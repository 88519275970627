<template>
  <div id="roleView">
    <transition name="slide-fade">
      <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
    </transition>
    <div class="row">
      <div class="col">
        <h4>manage application roles</h4>
        <app-modal :show="showAddEditModal" :title="role.id ? 'edit role': 'add new role'">
          <ul class="error">
            <li v-for="error in errors">
              {{ error.message }}
            </li>
          </ul>
          <form id="roleForm">
            <fieldset v-bind:disabled="isSavingRole">
              <floating-field type="email" id="roleName" label="name" v-model="role.name" isRequired></floating-field>
              <div class="form-group row mt-4">
                <input type="checkbox" class="form-control col-md-1 my-auto" style="margin-left:-15px" v-model="role.status" />
                <label class="col-md-4 mr-0 mt-2">{{ role.status | toStatusDescription }}</label>
              </div>
              <div class="mt-4">
                <h5 class="text-left">rights</h5>
                <div class="row">
                  <div class="row col-md-4 mr-0" v-for="right of roleRightsList">
                    <label class="col-md-10 mr-0 mt-2 text-left">{{ right.description }}</label>
                    <input type="checkbox" class="form-control col-md-2 my-auto" style="margin-left:-15px" v-model="right.isSelected" />
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSavingRole">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRole }" @click.prevent="saveRole" v-bind:disabled="isSavingRole">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showDeleteModal" title="remove role">
          <form>
            <fieldset v-bind:disabled="isDeletingRole">
              Are you sure you want to remove this role?
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelDelete" v-bind:disabled="isDeletingRole">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isDeletingRole }" @click.prevent="deleteRole" v-bind:disabled="isDeletingRole">Delete<span class="spinner"></span></button>
          </template>
        </app-modal>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary " @click="addRole"><i class="icon-expand-plus" title="add new role"></i></button>
        </div>
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <RoleList />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'
  import { toStatusDescription, toRoleRightsDescription } from '@/filters/enum'

  import { FETCH_ROLES, CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE } from '@/store/action-type'
  import { CLOSE_MODAL_EVENT, EDIT_ROLE_EVENT, REMOVE_ROLE_EVENT } from '@/utils/constants'
  import Enums from '@/utils/enums'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import FloatingField from '@/components/ui/FloatingField'
  import RoleList from '@/components/role/RoleList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'RoleView',
    components: {
      RoleList,
      AppAlert,
      AppModal,
      FloatingField
    },
    mixins: [GeneralMixin, AlertMixin, ErrorMixin],
    inject: ['eventBus'],
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    data: () => ({
      role: {
        id: null,
        name: '',
        rights: 0,
        status: Enums.Status.Active.value
      },
      roleRightsList: [],
      showAddEditModal: false,
      showDeleteModal: false,
      isSavingRole: false,
      isDeletingRole: false
    }),
    computed: {
      totalSelectedRoleRights() {
        let totalRights = 0
        for (let right in this.roleRightsList) {
          if (this.roleRightsList[right].isSelected) {
            totalRights = totalRights | this.roleRightsList[right].value
          }
        }
        return totalRights
      }
    },
    watch: {
      showAddEditModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('roleName').focus()
          })
        }
      }
    },
    methods: {
      addRole() {
        var righsList = Object.assign({}, Enums.RoleRights)
        for (let right in righsList) {
          righsList[right].isSelected = false
        }
        this.roleRightsList = righsList
        this.role.id = null
        this.role.name = ''
        this.role.rights = 0
        this.role.status = Enums.Status.Active.value
        this.showAddEditModal = true
      },
      editRole(role) {
        var righsList = Object.assign({}, Enums.RoleRights)
        for (let right in righsList) {
          righsList[right].isSelected = role.rights & righsList[right].value
        }
        this.roleRightsList = righsList
        this.role.id = role.id
        this.role.name = role.name
        this.role.rights = role.rights
        this.role.status = role.status
        this.showAddEditModal = true
      },
      saveRole() {
        if (this.validateAddOrEditForm()) {
          const self = this
          self.isSavingRole = true
          self.closeMessage()
          self.role.rights = self.totalSelectedRoleRights
          const actionType = self.role.id ? UPDATE_ROLE : CREATE_ROLE
          store.dispatch(actionType, self.role).then(() => {
            self.showSuccessMessage(`Role ${actionType == UPDATE_ROLE ? 'updated' : 'created'} successfully`)
            self.showAddEditModal = false
          }).catch((error) => {
            self.handleError(error)
          }).finally(() => {
            self.isSavingRole = false
          })
        }
      },
      deleteRole() {
        const self = this
        self.isDeletingRole = true
        self.closeMessage()
        store.dispatch(DELETE_ROLE, self.role).then(() => {
          self.showSuccessMessage(`Role removed successfully`)
          self.showDeleteModal = false
        }).catch((error) => {
          self.handleError(error)
        }).finally(() => {
          self.isDeletingRole = false
        })
      },
      cancelAddOrEdit() {
        this.showAddEditModal = false
      },
      cancelDelete() {
        this.showDeleteModal = false
      },
      validateAddOrEditForm() {
        this.errors.splice(0, this.errors.length)
        let form = document.querySelector('#roleForm')
        form.reportValidity()
        let errors = []
        if (!this.role.name) {
          errors.push({ message: 'role name is required' })
        }
        this.errors = [...errors]
        return !errors.length
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading roles')
      store.dispatch(FETCH_ROLES).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    filters: {
      toStatusDescription,
      toRoleRightsDescription
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_ROLE_EVENT, self.editRole)
      eventBus.$on(REMOVE_ROLE_EVENT, (role) => {
        this.roleRightsList = []
        this.role.id = role.id
        this.role.name = role.name
        this.role.rights = role.rights
        self.showDeleteModal = true
      })
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showAddEditModal = false
        self.showDeleteModal = false
      })
    }
  }
</script>
