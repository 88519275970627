<template>
  <div id="sickLeaveRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>generate vacation data</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <fieldset v-if="!isCompleted" v-bind:disabled="isSavingRequest || isCancellingRequest">
          <employee-autocomplete :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired :isDisabled="isSavingRequest || isSearchingEmployees" />
        </fieldset>
      </div>
    </div>
    <fieldset v-if="!isCompleted" v-bind:disabled="isSavingRequest || isCancellingRequest">
      <div class="row" v-if="requestCanBeRaisedForEmployee">
        <div class="col">
          <form id="vacationMasterGenerationForm" onsubmit="event.preventDefault();" novalidate>
            <div class="form-group row mt-4">
              <label class="col-md-4">number of vacation days</label>
              <input type="number" id="numberOfDays" class="form-control col-md-2" v-model="request.numberOfDays" required />
              <div class="col-md-3 invalid-feedback">
                please select a valid number.
              </div>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-4">remarks</label>
              <textarea type="number" id="remarks" rows="4" class="form-control col-md-5" v-model="request.remarks" required></textarea>
              <div class="col-md-3 invalid-feedback">
                please enter a comment.
              </div>
            </div>
          </form>
          <div class="offset-4 mt-4">
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="generateVacationData">Submit<span class="spinner"></span></button>
          </div>
        </div>
      </div>
    </fieldset>
    <div v-else-if="isCompleted" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import { mapState } from 'vuex'

  import { EMPLOYEE_SELECTED_EVENT } from '@/utils/constants'
  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, vacationMasterService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import AppAlert from '@/components/ui/AppAlert'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'
  import EmployeeSearchMixin from '@/mixins/employeeSearch'

  export default {
    name: 'VacationMasterForNewExpatsEntryView',
    components: {
      EmployeeAutocomplete,
      AppAlert,
    },
    mixins: [AlertMixin, ErrorMixin, EmployeeSearchMixin],
    data: () => ({
      request: {
        numberOfDays: null,
        remarks: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
      },
      errorMessageIfRequestCannotBeRaisedForEmployee: '',
      searchedEmployees: [],
      isSearchingEmployees: false,
      isSavingRequest: false,
      isCancellingRequest: false,
      isCompleted: false
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      }),
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          if (this.currentUser.isAdmin) {
            return this.searchedEmployees.filter(employee => employee.isRegularEmployee || employee.isExpatriateEmployee)
          }
          else if (this.currentUser.isExpatriateAdmin){
            return this.searchedEmployees.filter(employee.isExpatriateEmployee)
          }
        }
        return []
      },
      requestCanBeRaisedForEmployee() {
        if (this.employeeInfo.employeeId) {
          if (this.employeeInfo.employeeId.toLowerCase() == this.currentUser.id.toLowerCase()) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestForSelf)
            return false
          }
          if (!this.employeeInfo.isExpatriateEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.InvalidEmployeeType)
            return false
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      }
    },
    watch: {
      errorMessageIfRequestCannotBeRaisedForEmployee(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.showErrorMessage(newValue)
          })
        }
        else {
          this.closeMessage()
        }
      }
    },

    methods: {
      resetData() {
        this.employeeInfo.employeeId = ''
        this.employeeInfo.employeeNo = ''
        this.employeeInfo.fullName = ''
        this.employeeInfo.isExpatriateEmployee = false
        this.employeeInfo.isRegularEmployee = false
        this.request.numberOfDays = null
        this.request.remarks = ''
      },
      fetchEmployeeData(employee) {
        const self = this
        const tasks = []
        eventBus.$emit(Events.LongOperationStarted, '')
        tasks.push(employeeService.getEmployee(employee.employeeId))
        Promise.all(tasks).then((results) => {
          employee = results[0].data
          self.setData(employee)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee) {
        const self = this
        self.employeeInfo.employeeId = employee.employeeId
        self.employeeInfo.employeeNo = employee.employeeNo
        self.employeeInfo.fullName = employee.fullName
        self.employeeInfo.isFieldEmployee = employee.isFieldEmployee
        self.employeeInfo.isRegularEmployee = employee.isRegularEmployee
        self.employeeInfo.isExpatriateEmployee = employee.isExpatriateEmployee
      },
      generateVacationData() {
        const requestForm = document.getElementById('vacationMasterGenerationForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            "input.form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return
        }
        const self = this
        self.isSavingRequest = true
        eventBus.$emit(Events.LongOperationStarted, 'Generating vacation data')
        let request = { ...self.request }
        request.employeeId = self.employeeInfo.employeeId
        request.vacationYear = new Date().getFullYear()
        vacationMasterService.createVacationMasterManuallyFor(request).then((result) => {
          self.showSuccessMessage('vacation record created successfully')
          self.isCompleted = true
          self.resetData()
        }).catch((error) => {
          self.isSavingRequest = false
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      startNewRequest() {
        this.isCompleted = false
        this.closeMessage()
        this.$router.go()
      },
    },
    mounted() {
      eventBus.$on(EMPLOYEE_SELECTED_EVENT, this.fetchEmployeeData)
    },
    beforeDestroy() {
      eventBus.$off(EMPLOYEE_SELECTED_EVENT)
    }
  }
</script>
