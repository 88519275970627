import { render, staticRenderFns } from "./PublicHolidayList.vue?vue&type=template&id=2a77d973&scoped=true&"
import script from "./PublicHolidayList.vue?vue&type=script&lang=js&"
export * from "./PublicHolidayList.vue?vue&type=script&lang=js&"
import style0 from "./PublicHolidayList.vue?vue&type=style&index=0&id=2a77d973&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a77d973",
  null
  
)

export default component.exports