<script>
  export default {
    methods: {
      bayWatch(properties, object, method) {
        let iterator = function (prop) {
          object.$watch(prop, method);
        };
        properties.forEach(iterator, object);
      }
    }
  }
</script>
