var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.itemsToDisplay.length)?_c('div',[_c('data-table',{attrs:{"id":_vm.id,"headers":_vm.headers,"items":_vm.itemsToDisplay},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.endDate))+" ")]}},{key:"item.affectedStates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toStatesList")(item.affectedStates))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"action-btn mr-2 font-weight-bold h5",on:{"click":function($event){return _vm.editPublicHoliday(item)}}},[_vm._v("✎")]),_c('span',{staticClass:"action-btn font-weight-bold h5",on:{"click":function($event){return _vm.deletePublicHoliday(item)}}},[_vm._v("✖")])]}}],null,false,2331828207)}),_c('app-pager',{attrs:{"totalNumberOfItems":_vm.numberOfItems,"numberOfItemsPerPage":_vm.numberOfItemsPerPage,"numberOfPagers":_vm.numberOfPagers,"currentPage":_vm.currentPage},on:{"paginate":_vm.goToPage}})],1):_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v(" There are no public holidays set up for the current period ")])])}
var staticRenderFns = []

export { render, staticRenderFns }