<template>
  <div>
    <div v-if="itemsToDisplay.length">
      <data-table :id='id' :headers='headers' :items='itemsToDisplay'>
        <template v-slot:item.startDate="{ item }">
          {{ item.startDate | toShortDateString }}
        </template>
        <template v-slot:item.endDate="{ item }">
          {{ item.endDate | toShortDateString }}
        </template>
        <template v-slot:item.affectedStates="{ item }">
          {{ item.affectedStates | toStatesList }}
        </template>
      <template v-slot:item.actions="{ item }">
        <span class="action-btn mr-2 font-weight-bold h5" @click="editPublicHoliday(item)">&#x270E;</span>
        <span class="action-btn font-weight-bold h5" @click="deletePublicHoliday(item)">&#x2716;</span>
      </template>
      </data-table>
      <app-pager :totalNumberOfItems="numberOfItems" :numberOfItemsPerPage=numberOfItemsPerPage :numberOfPagers="numberOfPagers" :currentPage="currentPage" @paginate="goToPage" />
    </div>
    <div v-else class="font-weight-bold text-center">
      There are no public holidays set up for the current period
    </div>
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { EDIT_HOLIDAY_EVENT, DELETE_HOLIDAY_EVENT } from '@/utils/constants'

  import { toShortDateString, toStatesList } from '@/filters'

  import DataTable from '@/components/ui/DataTable'
  import AppPager from '@/components/ui/AppPager'

  export default {
    name: 'public-holiday-list',
    components: {
      DataTable,
      AppPager
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'requestList'
      }
    },
    data: () => ({
      headers: [
        {
          name: 'description',
          title: 'description'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'endDate',
          title: 'end date'
        },
        {
          name: 'numberOfDays',
          title: 'days'
        },
        {
          name: 'affectedStates',
          title: 'states'
        },
        {
          name: 'actions',
          title: ''
        }
      ],
      numberOfItemsPerPage: 20,
      numberOfPagers: 7,
      currentPage: 1
    }),
    computed: {
      ...mapState({
        holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      numberOfItems() {
        return this.holidays.length
      },
      itemsToDisplay() {
        return this.holidays.slice((this.currentPage - 1) * this.numberOfItemsPerPage, this.numberOfItemsPerPage * this.currentPage)
      }
    },
    watch: {
      itemsToDisplay(value) {
        if (!value.length) {
          this.currentPage = 1
        }
      }
    },
    filters: {
      toShortDateString,
      toStatesList
    },
    methods: {
      editPublicHoliday(holiday) {
        eventBus.$emit(EDIT_HOLIDAY_EVENT, holiday)
      },
      deletePublicHoliday(holiday) {
        eventBus.$emit(DELETE_HOLIDAY_EVENT, holiday)
      },
      goToPage(page) {
        this.currentPage = page
      }
    }
  }
</script>
