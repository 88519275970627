<template>
  <div id="userView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>manage users</h4>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary " @click="addUser"><i class="icon-expand-plus" title="add new user"></i></button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showAddEditModal" :title="user.isNew ? 'add new user': 'edit user'">
          <ul class="error">
            <li v-for="error in errors" :key="error.message">
              {{ error.message }}
            </li>
          </ul>
          <form id="userForm">
            <fieldset v-bind:disabled="isSavingUser">
              <employee-autocomplete v-if="user.isNew" :items="searchedEmployees" id="emp_search" :sData="user.name" :isShown="showAddEditModal" isRequired />
              <span v-else class="font-weight-bold">{{ user.name }}</span>
              <div class="form-group row mt-4">
                <input type="checkbox" class="form-control col-md-2 my-auto" style="margin-left:-30px" v-model="user.status" />
                <label class="col-md-4 mr-0 mt-2">{{ user.status | toStatusDescription }}</label>
              </div>
              <div class="form-group row mt-4">
                <!--<label class="col-md-2 mr-0 text-left">role<span class="text-danger font-weight-bold">*</span></label>-->
                <div class="col-md-8 mr-0">
                  <select class="form-control" id="userRole" name="userRole" v-model="user.roleId" required>
                    <option value="">-- select role --</option>
                    <option v-for="role in activeRoles" :value="role.id" :key="role.id">{{ role.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mt-4">
                <!--<label class="col-md-2 mr-0 text-left">role<span class="text-danger font-weight-bold">*</span></label>-->
                <div class="col-md-8 mr-0">
                  <select class="form-control" id="userReportRights" name="userReportRights" v-model="user.reportRights" required>
                    <option value="">-- select report rights --</option>
                    <option v-for="right in userReportRights" :value="right.value" :key="right.value">{{ right.description }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mt-4">
                <input type="checkbox" class="form-control col-md-2 my-auto" style="margin-left:-30px" v-model="user.isAdmin" />
                <label class="col-md-6 mr-0 mt-2">Is System Administrator?</label>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSavingUser">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingUser }" @click.prevent="saveUser" v-bind:disabled="isSavingUser">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showUserStatusChangeModal" title="remove user">
          <form>
            <fieldset v-bind:disabled="isChangingUserStatus">
              Are you sure you want to {{ selectedUserForStatusChange && selectedUserForStatusChange.status ? 'disable' : 'enable' }} this user?
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelUserStatusChange" v-bind:disabled="isChangingUserStatus">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isChangingUserStatus }" @click.prevent="changeUserStatus" v-bind:disabled="isChangingUserStatus">{{  statusForelectedUserForStatusChange ? 'Disable user' : 'Enable user' }}<span class="spinner"></span></button>
          </template>
        </app-modal>
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <user-list />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapGetters } from 'vuex'
  import { toStatusDescription } from '@/filters/enum'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import { FETCH_USERS, CREATE_USER, UPDATE_USER, CHANGE_USER_STATUS, FETCH_ROLES } from '@/store/action-type'
  import { CLOSE_MODAL_EVENT, EMPLOYEE_SEARCH_STARTED_EVENT, EMPLOYEE_SELECTED_EVENT, EDIT_USER_EVENT, CHANGE_USER_STATUS_EVENT } from '@/utils/constants'
  import Enums from '@/utils/enums'
  import { employeeService } from '@/services'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import UserList from '@/components/user/UserList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'UserView',
    components: {
      UserList,
      AppAlert,
      AppModal,
      EmployeeAutocomplete
    },
    mixins: [GeneralMixin, AlertMixin, ErrorMixin],
    data: () => ({
      user: {
        id: null,
        name: '',
        roleId: null,
        reportRights: '',
        roleName: '',
        status: Enums.Status.Active.value,
        isAdmin: false,
        isNew: true
      },
      selectedUserForStatusChange: null,
      statusForelectedUserForStatusChange: null,
      showAddEditModal: false,
      showUserStatusChangeModal: false,
      isSavingUser: false,
      isChangingUserStatus: false,
      searchedEmployees: [],
      userReportRights: Enums.UserReportRights
    }),
    computed: {
      ...mapGetters([
        'activeRoles'
      ])
    },
    watch: {
      showAddEditModal(value) {
        if (value && this.user.isNew) {
          this.$nextTick(() => {
            document.getElementById('emp_search').focus()
          })
        }
      }
    },
    methods: {
      setUser(employee) {
        this.user.name = employee.fullName
        this.user.id = employee.employeeId
      },
      addUser() {
        this.user.id = null
        this.user.name = ''
        this.user.roleId = ''
        this.user.roleName = ''
        this.user.reportRights = ''
        this.user.status = Enums.Status.Active.value
        this.user.isAdmin = false
        this.user.isNew = true
        this.showAddEditModal = true
      },
      editUser(user) {
        this.user = { ...user }
        this.user.roleId = this.user.roleId ? this.user.roleId : ''
        this.user.reportRights = this.user.reportRights ? this.user.reportRights : ''
        this.user.isNew = false
        this.showAddEditModal = true
      },
      saveUser() {
        if (this.validateAddOrEditForm()) {
          const self = this
          self.isSavingUser = true
          self.closeMessage()
          const actionType = self.user.isNew ? CREATE_USER : UPDATE_USER
          store.dispatch(actionType, self.user).then(() => {
            self.showSuccessMessage(`User ${actionType == UPDATE_USER ? 'updated' : 'created'} successfully`)
            self.showAddEditModal = false
          }).catch((error) => {
            self.handleError(error)
          }).finally(() => {
            self.isSavingUser = false
          })
        }
      },
      tryChangeUserStatus(user) {
        const self = this
        self.selectedUserForStatusChange = { ...user }
        self.statusForelectedUserForStatusChange = user.status
        self.showUserStatusChangeModal = true
      },
      changeUserStatus() {
        const self = this
        self.isChangingUserStatus = true
        self.closeMessage()
        self.selectedUserForStatusChange.status = self.selectedUserForStatusChange.status ? Enums.Status.Inactive.value : Enums.Status.Active.value
        store.dispatch(UPDATE_USER, self.selectedUserForStatusChange).then(() => {
          self.showSuccessMessage(`user ${self.selectedUserForStatusChange.status ? 'enabled' : 'disabled'} successfully`)
          self.showUserStatusChangeModal = false
        }).catch((error) => {
          self.handleError(error)
          self.selectedUserForStatusChange.status = self.statusForelectedUserForStatusChange
        }).finally(() => {
          self.isChangingUserStatus = false
        })
      },
      cancelAddOrEdit() {
        this.showAddEditModal = false
      },
      cancelUserStatusChange() {
        this.showUserStatusChangeModal = false
      },
      validateAddOrEditForm() {
        this.errors.splice(0, this.errors.length)
        let form = document.querySelector('#userForm')
        form.reportValidity()
        let errors = []
        if (!this.user.id) {
          errors.push({ message: 'No user selected' })
        }
        //if (!this.user.roleId) {
        //  errors.push({ message: 'No role selected' })
        //}
        this.errors = [...errors]
        return !errors.length
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading users')
      const tasks = []
      tasks.push(store.dispatch(FETCH_USERS))
      tasks.push(store.dispatch(FETCH_ROLES))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    filters: {
      toStatusDescription
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_USER_EVENT, self.editUser)
      eventBus.$on(CHANGE_USER_STATUS_EVENT, self.tryChangeUserStatus)
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showAddEditModal = false
      })
      eventBus.$on(EMPLOYEE_SEARCH_STARTED_EVENT, (searchString) => {
        self.user.name = ''
        self.user.id = null
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString)
          .then((result) => {
            if (result.data && result.data.length) {
              self.searchedEmployees = [...result.data]
            }
          })
      })

      eventBus.$on(EMPLOYEE_SELECTED_EVENT, self.setUser)
    },
    beforeDestroy() {
      eventBus.$off(EDIT_USER_EVENT)
      eventBus.$off(CHANGE_USER_STATUS_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
      eventBus.$off(EMPLOYEE_SEARCH_STARTED_EVENT)
      eventBus.$off(EMPLOYEE_SELECTED_EVENT)
    }
  }
</script>
