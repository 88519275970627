<template>
  <div id="publicHolidaysView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>manage public holidays</h4>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary " @click="addHoliday"><i class="icon-expand-plus" title="add new public holiday"></i></button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <!--<ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>-->
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showAddEditModal" :title="holiday.id ? 'edit public holiday': 'add new public holiday'" :static="false" :closeOnEscape="true">
          <ul class="error">
            <li v-for="error in errors">
              {{ error.message }}
            </li>
          </ul>
          <form id="publicHolidayForm" class="text-left" novalidate>
            <fieldset v-bind:disabled="isSaving">
              <div class="form-group row mt-4">
                <label class="col-md-3">desxription</label>
                <input type="text" class="form-control col-md-7" v-model="holiday.description" required />
                <!--<div class="col-md-4 invalid-feedback">
                  please input a valid description for this holiday.
                </div>-->
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-3">start date</label>
                <input type="date" id="startDate" class="form-control col-md-4" v-model="holiday.startDate" required :min="minimumStartDate" :max="maximumStartDate" v-bind:disabled="holiday.id !== null" />
                <span class="col-md-4"> {{ holiday.startDate | toLongDateString }}</span>
                <!--<div class="col-md-3 invalid-feedback">
                  please select a valid date.
                </div>-->
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-3">days</label>
                <input type="number" class="form-control col-md-1" v-model="holiday.numberOfDays" min="1" required v-bind:disabled="holiday.id !== null" />
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-3">end date</label>
                <input type="date" class="form-control col-md-3" v-model="holiday.endDate" readonly required />
                <span class="col-md-5"> {{ holiday.endDate | toLongDateString }}</span>
              </div>
              <div class="row mt-4">
                <label class="col-md-3">affected states</label>
                <div class="col-md-8">
                  <multiselect :multiple="true" label="description" track-by="id" :close-on-select="false" :show-labels="false" v-model="holiday.affectedStates" :options="states" v-bind:disabled="holiday.id !== null"></multiselect>
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSaving">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSaving }" @click.prevent="saveHoliday" v-bind:disabled="isSaving">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showDeleteModal" title="remove holiday">
          <form>
            <fieldset v-bind:disabled="isDeletingHoliday">
              Are you sure you want to remove this holiday?
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelDelete" v-bind:disabled="isDeletingHoliday">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isDeletingHoliday }" @click.prevent="deleteHoliday" v-bind:disabled="isDeletingHoliday">Delete<span class="spinner"></span></button>
          </template>
        </app-modal>
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <PublicHolidayList />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<style lang="scss">
  .multiselect__tag {
    margin-bottom: 5px !important;
    background: #0066b2;
  }

  .multiselect__option--highlight {
    background: #0066b2;
  }

  .multiselect__tag-icon:after {
    color: #ffffff
  }
</style>

<script>
  import store from '@/store'
  import { mapGetters } from 'vuex'
  import { toStatusDescription } from '@/filters/enum'
  import { toLongDateString, fromServerDate, getLeaveDates } from '@/utils/date'

  import { FETCH_CODETYPES, FETCH_PUBLICHOLIDAYS, CREATE_PUBLICHOLIDAY, UPDATE_PUBLICHOLIDAY, DELETE_PUBLICHOLIDAY } from '@/store/action-type'
  import Enums from '@/utils/enums'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { EDIT_HOLIDAY_EVENT, DELETE_HOLIDAY_EVENT } from '@/utils/constants'

  import Multiselect from 'vue-multiselect'
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import PublicHolidayList from '@/components/publicHoliday/PublicHolidayList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'
  import BayWatchMixin from '@/mixins/BayWatchMixin'

  export default {
    name: 'PublicHolidayView',
    components: {
      PublicHolidayList,
      AppAlert,
      AppModal,
      Multiselect
    },
    mixins: [GeneralMixin, AlertMixin, ErrorMixin, BayWatchMixin],
    data: () => ({
      holiday: {
        id: null,
        description: '',
        startDate: null,
        numberOfDays: null,
        endDate: null,
        affectedStates: [],
        status: Enums.Status.Active.value
      },
      //states: ['Lagos', 'Oyo', 'Adamawa', 'Bauchi', 'Borno'],
      showAddEditModal: false,
      showDeleteModal: false,
      isSaving: false,
      isDeletingHoliday: false
    }),
    computed: {
      ...mapGetters([
        'states'
      ]),
      minimumStartDate() {
        return fromServerDate(new Date(new Date().getFullYear(), 0, 1))
      },
      maximumStartDate() {
        return fromServerDate(new Date(new Date().getFullYear() + 1, 11, 31))
      },
    },
    watch: {
      showAddEditModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('startDate').focus()
          })
        }
      }
    },
    filters: {
      toLongDateString
    },
    methods: {
      setEndDate() {
        if (this.holiday.startDate && this.holiday.numberOfDays) {
          var dates = getLeaveDates(this.holiday.startDate, this.holiday.numberOfDays, false, false, [])
          this.holiday.endDate = dates.endDate
        }
        else {
          this.holiday.endDate = null
        }
      },
      addHoliday() {
        //this.holiday = Object.assign({}, {})
        this.holiday.id = null
        this.holiday.startDate = null
        this.holiday.numberOfDays = null
        this.holiday.endDate = null
        this.holiday.description = null
        this.holiday.affectedStates.splice(0, this.holiday.affectedStates.length)
        this.showAddEditModal = true
      },
      editHoliday(holiday) {
        this.holiday = { ...holiday }
        //console.log(holiday)
        //this.holiday.id = holiday.id
        //this.holiday.startDate = fromServerDate(holiday.startDate)
        //this.holiday.numberOfDays = holiday.numberOfDays
        //this.holiday.endDate = holiday.endDate
        //this.holiday.description = holiday.description
        //this.holiday.affectedStates =[]
        //this.holiday.affectedStates.push(...holiday.affectedStates)
        this.showAddEditModal = true
      },
      saveHoliday() {
        const self = this
        self.closeMessage()
        if (self.validateAddOrEditForm()) {
          self.isSaving = true
          const actionType = self.holiday.id ? UPDATE_PUBLICHOLIDAY : CREATE_PUBLICHOLIDAY
          if (self.holiday.affectedStates && self.holiday.affectedStates.length) {
            for (const affectedState of self.holiday.affectedStates) {
              affectedState.codeId = affectedState.id
            }
          }
          store.dispatch(actionType, self.holiday).then(() => {
            self.showSuccessMessage(`Holiday ${actionType == UPDATE_PUBLICHOLIDAY ? 'updated' : 'created'} successfully`)
            self.showAddEditModal = false
          }).catch((error) => {
            self.handleError(error)
          }).finally(() => {
            self.isSaving = false
          })
        }
        else {
          let form = document.querySelector('#publicHolidayForm')
          form.classList.add('was-validated')
        }
      },
      tryDeleteHoliday(holiday) {
        const self = this
        self.selectedHolidayToDelete = { ...holiday }
        self.showDeleteModal = true
      },
      deleteHoliday() {
        const self = this
        self.isDeletingHoliday = true
        self.closeMessage()
        store.dispatch(DELETE_PUBLICHOLIDAY, self.selectedHolidayToDelete).then(() => {
          self.showSuccessMessage(`Holiday removed successfully`)
          self.showDeleteModal = false
        }).catch((error) => {
          self.handleError(error)
        }).finally(() => {
          self.isDeletingHoliday = false
        })
      },
      cancelAddOrEdit() {
        let form = document.querySelector('#publicHolidayForm')
        form.classList.remove('was-validated')
        this.showAddEditModal = false
      },
      cancelDelete() {
        let form = document.querySelector('#publicHolidayForm')
        this.showDeleteModal = false
      },
      validateAddOrEditForm() {
        this.errors.splice(0, this.errors.length)
        let form = document.querySelector('#publicHolidayForm')
        return form.checkValidity()
        //form.reportValidity()
        //let errors = []
        //if (!this.role.name) {
        //  errors.push({ message: 'role name is required' })
        //}
        //this.errors = [...errors]
        //return !errors.length
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading public holidays')
      const tasks = []
      tasks.push(store.dispatch(FETCH_CODETYPES))
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    filters: {
      toStatusDescription,
      toLongDateString
    },
    mounted() {
      const self = this
      self.bayWatch(['holiday.startDate', 'holiday.numberOfDays'], self, self.setEndDate)
      const picker = document.getElementById('startDate');
      picker.addEventListener('change', function (e) {
        var day = new Date(this.value).getUTCDay();
        if ([6, 0].includes(day)) {
          e.preventDefault();
          alert('Weekends not allowed');
          this.value = '';
          self.holiday.startDate = null
          picker.click()
        }
      });
      eventBus.$on(EDIT_HOLIDAY_EVENT, self.editHoliday)
      eventBus.$on(DELETE_HOLIDAY_EVENT, self.tryDeleteHoliday)
    }
  }
</script>
