<template>
  <ul class="pageination text-center">
    <li class="prev" v-bind:class="{ noPointer: currentPage === 1 }" @click="goToPrevious">Previous</li>
    <li :class="{ active: currentPage == 1 }" @click="paginate(1)">1</li>
    <li v-if="hasLeftEllipses" class="noPointer">...</li>
    <li v-if="numberOfPages != 1" v-for="page in pages" :class="{ active: currentPage == page }" @click="paginate(page)" :key="page">{{ page }}</li>
    <li v-if="hasRightEllipses" class="noPointer">...</li>
    <li v-if="numberOfPages > 1" :class="{ active: currentPage == numberOfPages }" @click="paginate(numberOfPages)">{{ numberOfPages }}</li>
    <li class="next" v-bind:class="{ noPointer: currentPage === numberOfPages }" @click="goToNext">Next</li>
  </ul>
</template>

<style scoped lang="scss">
  ul.pageination {
    list-style: none;
  }

    ul.pageination li {
      display: inline;
      padding: 8px 16px;
      font-weight: bold;
      cursor: pointer;
    }

      ul.pageination li.noPointer, ul.pageination li.active {
        cursor: auto;
      }

      ul.pageination li.active {
        background-color: #0b2d71;
        color: white;
        border-radius: 5px;
      }

  ul.pagination li:hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
  }
</style>

<script>
export default {
  name: 'app-pager',
  props: {
    totalNumberOfItems: {
      type: Number,
      required: true,
      default: 0
    },
    numberOfItemsPerPage: {
      type: Number,
      required: true,
      default: 0
    },
    numberOfPagers: {
      type: Number,
      required: false,
      default: 7
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    hasLeftEllipses: false,
    hasRightEllipses: false
  }),
  computed: {
    numberOfPages () {
      return [-1, 0].includes(this.numberOfItemsPerPage) ? 1 : Math.ceil(this.totalNumberOfItems / this.numberOfItemsPerPage)
    },
    totalNumberOfPagers () {
      return this.numberOfPagers / 2 == 0 ? this.numberOfPagers - 1 : this.numberOfPagers
    },
    pages() {
      if (this.numberOfPages <= this.totalNumberOfPagers || this.totalNumberOfPagers < 1) {
        return Array.from({ length: this.numberOfPages }, (v, k) => k + 1).filter((value) => {
          return value != 1 && value != this.numberOfPages
        })
      } else {
        let pagesArray = []
        let mid = Math.ceil(this.totalNumberOfPagers / 2)
        this.hasLeftEllipses = this.currentPage >= (mid + 1)
        this.hasRightEllipses = this.currentPage <= (this.numberOfPages - mid)

        if (this.hasLeftEllipses && this.hasRightEllipses) {
          pagesArray.push(this.currentPage)
          let start = 1
          while (pagesArray.length < this.totalNumberOfPagers - 4) {
            pagesArray.push(this.currentPage - start)
            pagesArray.push(this.currentPage + start)
            start++
          }
        } else if (this.hasLeftEllipses) {
          let start = 1
          while (pagesArray.length < this.totalNumberOfPagers - 3) {
            pagesArray.push(this.numberOfPages - start++)
          }
        } else {
          let start = 2
          while (pagesArray.length < this.totalNumberOfPagers - 3) {
            pagesArray.push(start++)
          }
        }
        return pagesArray.sort()
      }
    }
  },
  methods: {
    paginate (to) {
      if (this.currentPage === to) {
        return
      }
      this.$emit('paginate', to)
    },
    goToPrevious () {
      if (this.currentPage === 1) {
        return
      }
      this.paginate(this.currentPage - 1)
    },
    goToNext () {
      if (this.currentPage === this.numberOfPages) {
        return
      }
      this.paginate(this.currentPage + 1)
    }
  }
}
</script>
